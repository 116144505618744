import "../assets/css/mini.css";

import React from "react";

import KonnectLogo from "../assets/images/konnect-logo.png";
import WelcomeImg from "../assets/images/welcome.png";
import { Link } from "react-router-dom";

const WelcomePage = () => {
    return (
        <div className="welcome-data">
            <div className="center-message">
                <div className="contain">
                    <img src={KonnectLogo} alt="logo" className="logo" />
                    <div className="welcome-img-cover">
                        <img src={WelcomeImg} alt="welcome-img" />
                        <div className="welcome-cover"></div>
                    </div>
                    <h3>Welcome to Konnect Drivers Portal</h3>
                    {/* <p>Get richer each day – as you refer, shop & keep cash aside for the future. We offer you a shopping
                        experience that comes with no stress. Get richer each day – as you refer.</p> */}
                </div>
                <div className="base-message">
                    <Link to="/signup" className="btn-white btn">Create a free account</Link>
                    <Link to="/signin" className="btn-blank btn mt_3">Log in to your account</Link>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage;