
import { Input, Drawer, notification, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import KonnectLogo from "../../assets/images/konnect-logo.png";
import LoginPage from "../../assets/images/login.jpg";
import Jwt_decrypt from '../../common/Jwt_decrypt';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import axios from '../../common/axios_call';
import { signIn } from '../../common/axios_services';
import { encrypted_token } from '../../slices/profileSlice';

import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import { ReactComponent as CartIcon } from "../../assets/images/icons/mobilecart.svg";

const SignInPage = () => {
    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');
    const [openAuthResponseModal, setOpenAuthResponseModal] = useState(false);

    const [newAccountModal, setNewAccountModal] = useState(localStorage.getItem('konnectnewaccountcreated') === 'true' ? true : false);

    const signInCheck = yup.object().shape({
        emailAddress: yup.string().email('Email address is not valid').required('Email address field is required'),
        password: yup.string().min(6).required('Password field is required'),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(signInCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const signInUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { emailAddress, password } = e;
        try {
            const response = await signIn({ email: emailAddress, password, app: "mobile", device_id: "", device_type: "", role_id: 3 })
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response.data.data.token }))
            openNotificationWithIcon('success', response.data.message)
            if (redir) {
                navigate(`/${redir}`)
            } else {
                navigate(`/home`);
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
                setErrorMessage(err.message);
            }
            // setOpenAuthResponseModal(true);
        }
    }

    return (
        <div className="signin-page">
            <div
                className={`top-bar contain mt_1`}>
                <div className="grid-flex top-welcome-address">
                    <div onClick={() => navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="">
                    <h3>Login to your account!</h3>
                    {/* <img src={LoginPage} alt='' /> */}
                    {/* <p>Konnect is easier to use when you have an account. Please enter your name and email address to login to your account.</p> */}
                </div>
                {
                    errorMessage.length ?
                        <p className="form-error"
                            style={{ marginBottom: 30, display: "block" }}>{errorMessage}</p>
                        : ""
                }
                <form onSubmit={handleSubmit(signInUser)}>
                    <div className="form_group">
                        <label htmlFor="emailAddress">Email address</label>
                        <Controller name="emailAddress" control={control}
                            render={({ field }) => (
                                <Input name="emailAddress" {...field} id="emailAddress"
                                    style={{ height: "5rem" }} />
                            )} />
                        {errors.emailAddress && <p className="form-error">{errors.emailAddress.message}</p>}
                    </div>
                    <div className="form_group">
                        <label htmlFor="emailAddress">Password</label>
                        <Controller name="password" control={control}
                            render={({ field }) => (
                                <Input.Password name="password" {...field} id="password"
                                    style={{ height: "5rem" }} />
                            )} />
                        {errors.password && <p className="form-error">{errors.password.message}</p>}
                    </div>
                    <div className="welcome-buttons">
                        <div className="welcome-buttons-content">
                            {
                                !fetchingData ?
                                    <button className="btn-base">Log in now</button>
                                    :
                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                            }
                        </div>
                    </div>
                </form>
                <div>
                    <Link to="/signup"><span>Create a new account</span></Link>
                </div>
                <div>
                    <Link to=""><span>Reset password now</span></Link>
                </div>
            </div>
            {/* <Drawer
                title=""
                placement="bottom"
                closable={false}
                onClose={() => setOpenAuthResponseModal(true)}
                open={openAuthResponseModal}
                key="bottom"
            >
                <div className="center-modal-content modal-content">
                    <LockIcon />
                    <h4>Login Failed</h4>
                    <p>{errorMessage}</p>
                    <button onClick={() => setOpenAuthResponseModal(true)} className="btn-base mt-3">Try again</button>
                </div>
            </Drawer> */}
        </div>
    )
}

export default SignInPage;