import React from "react";

import { ReactComponent as MenuIcon } from "../assets/images/menu.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/images/arrow-right.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/images/arrow-left-circle.svg";

import { Link } from "react-router-dom";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import Footer from "../components/footer";

const PickupPage = () => {

    const breakpoints = {
        0: {
            slidesPerView: 2.1
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    return (
        <div className="app-data">
            <div className="app-display">
                <div className="brown-bg">
                    <div className="contain">
                        <div className="app-display-header">
                            <h3 className="page-title"><ArrowLeftIcon /> Pickup History</h3>
                            <div>
                                <BellIcon className="bell-icon" />
                            </div>
                        </div>
                        <div className="app-data-filter">
                            <ul>
                                <li className="active-filter">Today</li>
                                <li>Week</li>
                                <li>Month</li>
                                <li>All History</li>
                            </ul>
                        </div>
                        <div className="app-data-summary">
                            <div className="grid-2">
                                <div className="summary-box _1">
                                    <h4>18</h4>
                                    <p>Buyers</p>
                                </div>
                                <div className="summary-box _2">
                                    <h4>22</h4>
                                    <p>Items</p>
                                </div>
                                <div className="summary-box _3">
                                    <h4>34</h4>
                                    <p>Quantities</p>
                                </div>
                                <div className="summary-box _4">
                                    <h4>81kg</h4>
                                    <p>Weight</p>
                                </div>
                            </div>
                            <>
                                {/* <Swiper
                        spaceBetween={5} slidesPerView={4.2} breakpoints={breakpoints}>
                        <SwiperSlide key={1}>
                            <div className="summary-box _1">
                                <h4>18</h4>
                                <p>Buyers</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <div className="summary-box _2">
                                <h4>22</h4>
                                <p>Items</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3}>
                            <div className="summary-box _3">
                                <h4>34</h4>
                                <p>Quantities</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={4}>
                            <div className="summary-box _4">
                                <h4>81kg</h4>
                                <p>Weight</p>
                            </div>
                        </SwiperSlide>
                    </Swiper> */}
                            </>
                        </div>
                        <button className="deliveries btn btn-black">Report a Receiver</button>
                    </div>
                </div>
                <div className="data-delivery-details mt_10">
                    <div className="contain">
                        <h2>All Deliveries</h2>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <Link to="/pickup-detail">View Order <ArrowRightIcon /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PickupPage;