import React, { useEffect, useState } from "react";

import { Divider, Input, Select, Spin, Tabs, notification } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from 'react-number-format';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import 'swiper/css';


import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";
import { _complete_bank_transfer, _confirm_user_transfer_pin, _fetch_bank_list, _fetch_van_types, _fund_user_wallet, _submit_driver_onsite_data, _verify_user_bank_account } from "../../common/axios_services";
import AppRoutes from "../../common/routes";


const OnSitePage = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
        });
    };

    const digitsOnly = (value) => /^\d+$/.test(value);
    const validator = yup.object().shape({
        phone_number: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly),
        plate_no: yup.string().required('Plate number field is required'),
        // van_type: yup.string().required('Van type field is required'),
        capacity: yup.string().required('Capacity field is required'),
    });

    // start form handlers
    const { handleSubmit, control, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validator)
    });


    const [currentDispTab, setCurrentDispTab] = useState("wallet");
    const [loadingData, setLoadingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [fundUserWalletAmount, setfundUserWalletAmount] = useState(0);
    const [saveCardDetail, setSaveCardDetail] = useState(false);
    const [setActiveFundWalletData] = useState("");
    const [fetchingData, setFetchingData] = useState(false);
    const [vanTypes, setVanTypes] = useState([]);
    const [loadingPageSpinner, setLoadingPageSpinner] = useState(true);
    const [selectedVan, setSelectedVan] = useState({
        _id: "",
        capacity: ""
    });
    const [fixed, setFixed] = useState(false);

    const fetchVanType = async () => {
        try {
            let userData = await _fetch_van_types();
            setVanTypes(userData.data.data);
            setLoadingPageSpinner(false);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching van types. Please check network and try again');
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching van types. Please try again');
            }
            setLoadingPageSpinner(false);
        }
    }

    const updateVanTypeSelection = e => {
        setSelectedVan({
            _id: e.split("---")[0],
            capacity: e.split("---")[1]
        })
        setValue("capacity", e.split("---")[1]);
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
        fetchVanType();
    }, [])

    const submitDriverOnsiteData = async (e) => {
        setFetchingData(true);
        try {
            let userData = await _submit_driver_onsite_data({
                van_capacity: selectedVan.capacity,
                plate_number: e.plate_no,
                comment: e.comment,
                van_type: selectedVan._id,
                driver_contact: e.phone_number
            });
            openNotificationWithIcon('success', 'Onsite data submitted successfully');
            Navigate(AppRoutes.home);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while saving data. Please check network and try again');
            } else {
                openNotificationWithIcon('error', 'An error occurred while saving data. Please try again');
            }
            setErrorMessage(err?.response?.message ? err?.response?.message : err.message);
            setFetchingData(false);
        }
    }

    return (
        <div className="display-page">
            <Spin spinning={loadingPageSpinner}>
                <div className="small-page-title contain">
                    <div className="grid-flex">
                        <div
                            onClick={() => Navigate(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        <NotificationIcon />
                    </div>
                </div>
                <div className="display-main-content">
                    <div className="hero-sect">
                        <div className="container">
                            <h3 className="sec-page-title">On-site Setting</h3>
                            <div className="top-selection-bar">
                                <div
                                    onClick={() => { setCurrentDispTab("wallet") }}
                                    className={`select-bar ${currentDispTab === "wallet" ? "active-bar" : ""}`}>
                                    <div className="active-inner-text">
                                        <h4>On-Site</h4>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="mt_10">
                                    <form onSubmit={handleSubmit(submitDriverOnsiteData)}>
                                        {
                                            errorMessage.length ?
                                                <p className="form-error"
                                                    style={{ marginBottom: 30, display: "block" }}>{errorMessage}</p>
                                                : ""
                                        }
                                        <div className="form_group">
                                            <label htmlFor="phone_number">Driver Mobile Number</label>
                                            <Controller name="phone_number" control={control}
                                                render={({ field }) => (
                                                    <Input type="tel" name="phone_number" {...field} id="phone_number"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                            {errors.phone_number && <p className="form-error">{errors.phone_number.message}</p>}
                                        </div>
                                        <div className="grid-2">
                                            <div className="form_group">
                                                <label htmlFor="van_type">Van Type</label>
                                                <Controller name="van_type" control={control}
                                                    render={({ field }) => (
                                                        <Select name="van_type" {...field} id="van_type" onChange={updateVanTypeSelection}
                                                            style={{ width: "100%", display: "block", height: "5rem" }}>
                                                            {
                                                                vanTypes.map((van, index) => (
                                                                    <Select.Option value={`${van.id}---${van.capacity}`} key={index}>{van.name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    )} />
                                                {errors.van_type && <p className="form-error">{errors.van_type.message}</p>}
                                            </div>
                                            <div className="form_group">
                                                <label htmlFor="capacity">Capacity</label>
                                                <Controller name="capacity" control={control}
                                                    render={({ field }) => (
                                                        <Input name="capacity" {...field} id="capacity" disabled value={selectedVan.capacity}
                                                            style={{ height: "5rem" }} />
                                                    )} />
                                                {errors.capacity && <p className="form-error">{errors.capacity.message}</p>}
                                            </div>
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="plate_no">Plate Number</label>
                                            <Controller name="plate_no" control={control}
                                                render={({ field }) => (
                                                    <Input name="plate_no" {...field} id="plate_no"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                            {errors.plate_no && <p className="form-error">{errors.plate_no.message}</p>}
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="comment">Comments</label>
                                            <Controller name="comment" control={control}
                                                render={({ field }) => (
                                                    <Input.TextArea name="comment" {...field} id="comment"
                                                        rows={5} />
                                                )} />
                                        </div>
                                        <div className="welcome-buttons">
                                            <div className="welcome-buttons-content">
                                                {
                                                    !fetchingData ?
                                                        <button className="btn-base btn-full">Continue</button>
                                                        :
                                                        <button className="btn-base btn-full" disabled><Spin indicator={antIcon} /></button>
                                                }
                                            </div>
                                        </div>
                                        {/* <button onClick={() => setOpenOnsiteModal(false)} type="button"
                            className="btn-blank btn-full btn mt_3">Cancel</button> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default OnSitePage;