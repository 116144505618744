import "../assets/css/home.css";

import React, { useState, useEffect } from "react";

import { Input, Divider, Drawer, notification, Spin, Select } from "antd";
import { DateTime } from 'luxon';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import Jwt_decrypt from '../common/Jwt_decrypt';
import axiosCall from '../common/axios_call';

import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as CancelIcon } from "../assets/images/icons/x.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as PocketIcon } from "../assets/images/icons/pocket.svg";
import { ReactComponent as PlusIcon } from "../assets/images/icons/plus.svg";
import { ReactComponent as PieChartIcon } from "../assets/images/icons/pie-chart.svg";
import { ReactComponent as MenuIcon } from "../assets/images/icons/menu.svg";
import { ReactComponent as GiftIcon } from "../assets/images/icons/gift.svg";
import { ReactComponent as HardDriveIcon } from "../assets/images/icons/hard-drive.svg";

import { encrypted_token, fetchData, profileData } from '../slices/profileSlice';


import FooterDisplay from "../components/footer";
import { Controller, useForm } from "react-hook-form";
import { _check_delivery_date, _fetch_van_types } from "../common/axios_services";
import AppRoutes from "../common/routes";


const Dashboard = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const { handleSubmit, control, formState: { errors } } = useForm({});

    const [currDisplay, setCurrDisplay] = useState("pickup");
    const [openReturnsModal, setOpenReturnsModal] = useState(false);
    const [openOnsiteModal, setOpenOnsiteModal] = useState(false);
    const [openValidationModal, setOpenValidationModal] = useState(false);
    const [fixedNav, setFixed] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [vanTypes, setVanTypes] = useState([]);
    const [displayOnSiteButton, setDisplayOnSiteButton] = useState(false);
    const [loadingPageSpinner, setLoadingPageSpinner] = useState(true);

    const loadOnsiteDeliveryCheck = async () => {
        try {
            let userData = await _check_delivery_date();
            if (userData.data.data) setDisplayOnSiteButton(true)
            setLoadingPageSpinner(false);
            // setVanTypes(userData.data.data);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon("error", 'An error occurred while checking if delivery is available. Please check network and try again');
            } else {
                openNotificationWithIcon("error", 'An error occurred while checking if delivery is available. Please try again');
            }
            setLoadingPageSpinner(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
        loadOnsiteDeliveryCheck();
    }, [])


    return (
        <div className="homepage wise9ja-disp app-display">
            <Spin spinning={loadingPageSpinner}>
                <div className="wise9ja-card-detail">
                    <div
                        className={`top-bar ${fixedNav ? 'fixed' : ''} contain`}>
                        <div className="grid-flex top-welcome-address">
                            <div>
                                <NotificationIcon className="menu-icon" />
                            </div>
                            <div>
                                <h5>Hi, Ifeoluwase</h5>
                            </div>
                            <div>
                                <div className="cart-box">
                                    <CartIcon />
                                    <h4>12</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contain mt_4">
                        <div className="grid-2 content-grid-cover">
                            <div className="content-summary-box _1">
                                <div className="content-summary-info">
                                    <h4><span className="currency">NGN</span>0.00</h4>
                                    <p>Wallet balance</p>
                                </div>
                            </div>
                            <div className="content-summary-box _2">
                                <div className="content-summary-info">
                                    <h4><span className="currency">NGN</span>0.00</h4>
                                    <p>Wise9ja amount</p>
                                </div>
                            </div>
                            <div className="content-summary-box _3">
                                <div className="content-summary-info">
                                    <h4><span className="currency">NGN</span>0.00</h4>
                                    <p>Cash earned</p>
                                </div>
                            </div>
                            <div className="content-summary-box _4">
                                <div className="content-summary-info">
                                    <h4><span className="currency">NGN</span>0.00</h4>
                                    <p>Cash to earn</p>
                                </div>
                            </div>
                        </div>
                        {
                            displayOnSiteButton ?
                                <div className="mt_4">
                                    <Link to={AppRoutes.onsite} className="btn btn-base">On Site</Link>
                                </div>
                                : ""
                        }
                    </div>
                </div>

                <div className="hero-sect">
                    <div className="contain">
                        <div className="extra-data-message mt_4">
                            <div className="extra-data-content">
                                <h3>Get 10% off groceries with Plus+ T&C Apply</h3>
                                <div>
                                    <p>Spend $30.00 to get 5%</p>
                                </div>
                            </div>
                            <div className="extra-div _1"></div>
                            <div className="extra-div _2"></div>
                            <div className="extra-div _3"></div>
                        </div>
                    </div>
                </div>
                <div className="content-display-content">
                    <div className="contai">
                        <div className="wise9ja-action-bar">

                        </div>
                        {/* <div className="mt_7 history-data-detail"> */}
                        <div className="mt_7 inner-detail disputes">
                            <div className="inner-title">
                                <div
                                    onClick={() => setCurrDisplay('pickup')}
                                    className={`${currDisplay === "pickup" ? "active" : ""} title-list`}>
                                    <h2>Today's Pickups</h2>
                                </div>
                                <div
                                    onClick={() => setCurrDisplay('deliveries')}
                                    className={`${currDisplay === "deliveries" ? "active" : ""} title-list`}>
                                    <h2>Today's Deliveries</h2>
                                </div>
                            </div>
                            {/* <h3 className="tile-header">Wise9ja History</h3> */}
                            <div className="contain">
                                {
                                    currDisplay === "pickup" ?
                                        <div className="data-delivery-details">
                                            <div className="items-list">
                                                <div className="item-single-list">
                                                    <div className="items-name-detail">
                                                        <div>
                                                            <div className="img-cover"></div>
                                                        </div>
                                                        <div className="item-name">
                                                            <div>
                                                                <div className="grid-fle">
                                                                    <div>
                                                                        <h4>Honeywell Noodles</h4>
                                                                        <div className="grid-flex">
                                                                            <p>Ordered: 60</p>
                                                                            <p>Received: &mdash;</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-single-list">
                                                    <div className="items-name-detail">
                                                        <div>
                                                            <div className="img-cover"></div>
                                                        </div>
                                                        <div className="item-name">
                                                            <div>
                                                                <div className="grid-fle">
                                                                    <div>
                                                                        <h4>Honeywell Noodles</h4>
                                                                        <div className="grid-flex">
                                                                            <p>Ordered: 60</p>
                                                                            <p>Received: &mdash;</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-single-list">
                                                    <div className="items-name-detail">
                                                        <div>
                                                            <div className="img-cover"></div>
                                                        </div>
                                                        <div className="item-name">
                                                            <div>
                                                                <div className="grid-fle">
                                                                    <div>
                                                                        <h4>Honeywell Noodles</h4>
                                                                        <div className="grid-flex">
                                                                            <p>Ordered: 60</p>
                                                                            <p>Received: &mdash;</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="data-delivery-details">
                                            <div className="items-list">
                                                <div className="data-detail">
                                                    <div className="data-detail-header">
                                                        <div className="data-header-block">
                                                            <div className="header-icon-cover">
                                                                <div className="header-icon-box"></div>
                                                                <div>
                                                                    <p className="muted">ID number</p>
                                                                    <h4>JIE2EINI322</h4>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h5>Ikotun, Lagos State</h5>
                                                                <p className="delivery-status">processing</p>
                                                            </div>
                                                        </div>
                                                        <div className="divider-line"></div>
                                                        <div className="receiver-detail">
                                                            <div>
                                                                <h5>Adeleke Ifeoluwase</h5>
                                                                <p>08132277316</p>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-detail">
                                                    <div className="data-detail-header">
                                                        <div className="data-header-block">
                                                            <div className="header-icon-cover">
                                                                <div className="header-icon-box"></div>
                                                                <div>
                                                                    <p className="muted">ID number</p>
                                                                    <h4>JIE2EINI322</h4>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h5>Ikotun, Lagos State</h5>
                                                                <p className="delivery-status">processing</p>
                                                            </div>
                                                        </div>
                                                        <div className="divider-line"></div>
                                                        <div className="receiver-detail">
                                                            <div>
                                                                <h5>Adeleke Ifeoluwase</h5>
                                                                <p>08132277316</p>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-detail">
                                                    <div className="data-detail-header">
                                                        <div className="data-header-block">
                                                            <div className="header-icon-cover">
                                                                <div className="header-icon-box"></div>
                                                                <div>
                                                                    <p className="muted">ID number</p>
                                                                    <h4>JIE2EINI322</h4>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h5>Ikotun, Lagos State</h5>
                                                                <p className="delivery-status">processing</p>
                                                            </div>
                                                        </div>
                                                        <div className="divider-line"></div>
                                                        <div className="receiver-detail">
                                                            <div>
                                                                <h5>Adeleke Ifeoluwase</h5>
                                                                <p>08132277316</p>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <FooterDisplay />
            </Spin>
        </div>
    )
}

export default Dashboard;