import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../assets/images/home-cropped.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";
import { ReactComponent as CartIcon } from "../assets/images/shopping-cart.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings-cropped.svg";
import { ReactComponent as LogOutIcon } from "../assets/images/log-out-cropped.svg";
import Footer from "../components/footer";

const Settings = () => {
    return (
        <div>
            <div className="contain">
                <div className="driver-settings-name">
                    <div>
                        <h2>Ifeoluwase Adeleke</h2>
                        <p>driver since 01/08/2023</p>
                    </div>
                    <div>
                        <div className="blank-avatar"></div>
                    </div>
                </div>
                <div className="extra-profile-cover mt_7">
                    <div className="extra-profile-link">
                        <SettingsIcon />
                        <h3>Help</h3>
                    </div>
                    <div className="extra-profile-link">
                        <SettingsIcon />
                        <h3>Payments</h3>
                    </div>
                    <div className="extra-profile-link">
                        <SettingsIcon />
                        <h3>Trips</h3>
                    </div>
                </div>
                <div className="banner-design mt_7">
                </div>
                <div className="setings-links mt_7">
                    <ul>
                        <li>
                            <Link to=""><SettingsIcon /> Update Basic Info</Link>
                        </li>
                        <li>
                            <Link to=""><CartIcon /> Delivery Info</Link>
                        </li>
                        <li>
                            <Link to=""><BellIcon /> Notifications</Link>
                        </li>
                        <li>
                            <Link to=""><SettingsIcon /> Contact Support</Link>
                        </li>
                        <li>
                            <Link to=""><LogOutIcon /> Log Out</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Settings;