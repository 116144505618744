import React, { useState } from "react";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ReactComponent as ArrowLeftIcon } from "../assets/images/arrow-left-circle.svg";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as LocationIcon } from "../assets/images/map-pin.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";

import { Modal, Tabs, Radio } from "antd";
import { Link } from "react-router-dom";
import Footer from "../components/footer";

const PickupDetails = () => {

    const breakpoints = {
        0: {
            slidesPerView: 2.2
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    const [orderPriority, setOrderPriority] = useState('next');
    const [openPriorityModal, setOpenPriorityModal] = useState(false);

    const onChangePriorityOption = ({ target: { value } }) => {
        setOrderPriority(value);
    };

    const radioOptions = [{
        label: 'Return',
        value: 'return',
    },
    {
        label: 'Next',
        value: 'next',
    },
    {
        label: 'Arrive',
        value: 'arrive',
    }]

    return (
        <div className="app-data inner-detail">
            <div className="app-display">
                <div className="contain">
                    <div className="app-display-header">
                        <h3 className="page-title"><ArrowLeftIcon /> Package History</h3>
                        <div>
                            <BellIcon className="bell-icon" />
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                {/* <div className="map-box">
                    <div></div>
                </div> */}
            </div>
            <div className="inner-detail-content">
                <div className="data-header-block">
                    <div className="header-icon-cover">
                        <div className="header-icon-box"></div>
                        <div>
                            <p className="muted">ID number</p>
                            <h4>JIE2EINI322</h4>
                        </div>
                    </div>
                    <div>
                        <p className="priority-display"><span class="yellow"></span>Next</p>
                        <p className="delivery-status">processing</p>
                    </div>
                </div>
            </div>
            <div className="inner-info-content mt_7">
                <div className="contain">
                    <h3 className="inner-tile">Order Summary</h3>
                    <Swiper
                        spaceBetween={5} slidesPerView={4.2} breakpoints={breakpoints}>
                        <SwiperSlide key={1}>
                            <div className="summary-box _1">
                                <LocationIcon />
                                <p>Destination</p>
                                <h4>Ikotun, Lagos State</h4>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <div className="summary-box _2">
                                <CalendarIcon />
                                <p>Estimated Date</p>
                                <h4>Sunday October 22, 2024</h4>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3}>
                            <div className="summary-box _3">
                                <UserIcon />
                                <p>Receiver</p>
                                <h4>Mr Adeleke Ifeoluwase</h4>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="extra-info-content">
                <div className="contain">
                    <h3 className="inner-tile">Order Information</h3>
                    <div>
                        <Tabs type="card">
                            <Tabs.TabPane key={1} tab="Delivery Information">
                                <div>
                                    <div className="address">
                                        <div>
                                            <h4>Destination</h4>
                                            <p>5, Sanusi street, Council Bus Stop, Ikotun Lagos State.</p>
                                        </div>
                                        {/* <div>
                                            <h4>Quantity</h4>
                                            <p>58 products</p>
                                        </div> */}
                                    </div>
                                    <div className="grid-2">
                                        <div>
                                            <h4>Receiver</h4>
                                            <p>Mr. Adeleke Ifeoluwase</p>
                                        </div>
                                        <div>
                                            <h4>Phone number</h4>
                                            <p>08132277316</p>
                                        </div>
                                    </div>
                                    <div className="grid-2">
                                        <div>
                                            <h4>Payment Option</h4>
                                            <p>Payment at Delivery</p>
                                        </div>
                                        <div>
                                            <h4>Delivery Date</h4>
                                            <p>Sunday November 05, 2023</p>
                                        </div>
                                    </div>
                                    <div className="action-button">
                                        <button
                                            onClick={() => setOpenPriorityModal(true)}
                                            className="btn-green">Set Order Priority</button>
                                        <button
                                            onClick={() => setOpenPriorityModal(true)}
                                            className="btn-border-green">Set Order Status</button>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane key={2} tab="Items to Deliver (4)">
                                <div className="items-list">
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <h4>Honeywell Noodles</h4>
                                                <div className="grid-flex">
                                                    <p>Quantity: 60</p>
                                                    <p>Discount: 6%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div>
                                    </div>
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <h4>Honeywell Noodles</h4>
                                                <div className="grid-flex">
                                                    <p>Quantity: 60</p>
                                                    <p>Discount: 6%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div>
                                    </div>
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <h4>Honeywell Noodles</h4>
                                                <div className="grid-flex">
                                                    <p>Quantity: 60</p>
                                                    <p>Discount: 6%</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal open={openPriorityModal} footer={null} onCancel={() => setOpenPriorityModal(false)}>
                <div className="priorities-modal">
                    <h3>Change Order Priority</h3>
                    <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p>
                    <div className="mt_7">
                        <div>
                            <label>Select Option</label>
                            <div>
                                <Radio.Group
                                    options={radioOptions}
                                    onChange={onChangePriorityOption}
                                    value={orderPriority}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        {
                            orderPriority === "return" ?
                                <div className="mt_5">
                                    <label>Upload Evidence</label>
                                    <div className="banner-design"></div>
                                </div> : ""
                        }
                        <div className="mt_7">
                            <button
                                onClick={() => setOpenPriorityModal(false)}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PickupDetails;