import React, { useState } from "react";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ReactComponent as ArrowLeftIcon } from "../assets/images/arrow-left-circle.svg";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as LocationIcon } from "../assets/images/map-pin.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";

import { Modal, Tabs, Radio, Input } from "antd";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { Controller, useForm } from "react-hook-form";

const DeliveryDetails = () => {

    const breakpoints = {
        0: {
            slidesPerView: 2.2
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    const { handleSubmit, control } = useForm({});
    const [orderPriority, setOrderPriority] = useState('next');
    const [returnsReason, setReturnsReason] = useState('unavailable');
    const [userPaymentOptions, setUserPaymentOptions] = useState('card');
    const [openPriorityModal, setOpenPriorityModal] = useState(false);
    const [openReturnsModal, setOpenReturnsModal] = useState(false);
    const [paymentOptionModal, setPaymentOptionModal] = useState(false);

    const onChangePriorityOption = ({ target: { value } }) => {
        setOrderPriority(value);
    };

    const onChangeReturnsOption = ({ target: { value } }) => {
        setReturnsReason(value);
    };

    const onChangePaymentOptions = ({ target: { value } }) => {
        setUserPaymentOptions(value);
    };

    const radioOptions = [{
        label: 'Return',
        value: 'return',
    },
    {
        label: 'Next',
        value: 'next',
    },
    {
        label: 'Arrive',
        value: 'arrive',
    }]

    const returnsOptions = [{
        label: 'N/A',
        value: 'none',
    }, {
        label: 'Unavailable',
        value: 'unavailable',
    },
    {
        label: 'Damaged',
        value: 'damaged',
    }]
    const paymentOptions = [{
        label: 'Card',
        value: 'card',
    },
    {
        label: 'Wallet',
        value: 'wallet',
    }]

    return (
        <div className="app-data inner-detail">
            <div className="app-display">
                <div className="contain">
                    <div className="app-display-header">
                        <h3 className="page-title"><ArrowLeftIcon /> Package History</h3>
                        <div>
                            <BellIcon className="bell-icon" />
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                {/* <div className="map-box">
                    <div></div>
                </div> */}
            </div>
            <div className="inner-detail-content">
                <div className="data-header-block">
                    <div className="header-icon-cover">
                        <div className="header-icon-box"></div>
                        <div>
                            <p className="muted">ID number</p>
                            <h4>JIE2EINI322</h4>
                        </div>
                    </div>
                    <div>
                        <p className="priority-display"><span class="yellow"></span>Next</p>
                        <p className="delivery-status">processing</p>
                    </div>
                </div>
            </div>
            <div className="inner-info-content mt_7">
                <div className="contain">
                    <h3 className="inner-tile">Order Summary</h3>
                    <Swiper
                        spaceBetween={5} slidesPerView={4.2} breakpoints={breakpoints}>
                        <SwiperSlide key={1}>
                            <div className="summary-box _1">
                                <LocationIcon />
                                <p>Destination</p>
                                <h4>Ikotun, Lagos State</h4>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <div className="summary-box _2">
                                <CalendarIcon />
                                <p>Estimated Date</p>
                                <h4>Sunday October 22, 2024</h4>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3}>
                            <div className="summary-box _3">
                                <UserIcon />
                                <p>Receiver</p>
                                <h4>Mr Adeleke Ifeoluwase</h4>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="contain">
                <button
                    onClick={() => setOpenPriorityModal(true)}
                    className="btn-green priority-btn">Set Order Priority</button>
            </div>
            <div className="extra-info-content">
                <div className="contain">
                    <h3 className="inner-tile">Order Information</h3>
                    <div>
                        <Tabs type="card">
                            <Tabs.TabPane key={1} tab="Delivery Information">
                                <div>
                                    <div className="address">
                                        <div>
                                            <h4>Destination</h4>
                                            <p>5, Sanusi street, Council Bus Stop, Ikotun Lagos State.</p>
                                        </div>
                                        {/* <div>
                                            <h4>Quantity</h4>
                                            <p>58 products</p>
                                        </div> */}
                                    </div>
                                    <div className="grid-2">
                                        <div>
                                            <h4>Receiver</h4>
                                            <p>Mr. Adeleke Ifeoluwase</p>
                                        </div>
                                        <div>
                                            <h4>Phone number</h4>
                                            <p>08132277316</p>
                                        </div>
                                    </div>
                                    <div className="grid-2">
                                        <div>
                                            <h4>Payment Option</h4>
                                            <p>Payment at Delivery</p>
                                        </div>
                                        <div>
                                            <h4>Delivery Date</h4>
                                            <p>Sunday November 05, 2023</p>
                                        </div>
                                    </div>
                                    <div className="grid-2">
                                        <div>
                                            <h4>Amount Paid</h4>
                                            <p>NGN 50,000.00</p>
                                        </div>
                                        <div>
                                            <h4>Amount to Pay</h4>
                                            <p>NGN 20,000.00</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h4>Payment Link</h4>
                                        <button onClick={() => setPaymentOptionModal(true)}>Click to pay now</button>
                                        {/* <p>https://paymentlink.payment.com</p> */}
                                    </div>
                                    <div className="action-button">
                                        {/* <button
                                            onClick={() => setOpenPriorityModal(true)}
                                            className="btn-green">Set Order Priority</button> */}
                                        <button
                                            onClick={() => setOpenPriorityModal(true)}
                                            className="btn-green">Set Order Status</button>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane key={2} tab="Items to Deliver (4)">
                                <div className="items-list">
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <div className="grid-fle">
                                                    <div>
                                                        <h4>Honeywell Noodles</h4>
                                                        <p>Quantity Ordered: 60</p>
                                                        <div className="grid-flex">
                                                            <p>Delivered: 60</p>
                                                            <p>Dispute: 0</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div> */}
                                    </div>
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <div className="grid-flex">
                                                    <div>
                                                        <h4>Honeywell Noodles</h4>
                                                        <p>Quantity: 60</p>
                                                        {/* <p>Discount: 6%</p> */}
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div> */}
                                    </div>
                                    <div className="item-single-list">
                                        <div className="items-name-detail">
                                            <div>
                                                <div className="img-cover"></div>
                                            </div>
                                            <div className="item-name">
                                                <div className="grid-flex">
                                                    <div>
                                                        <h4>Honeywell Noodles</h4>
                                                        <p>Quantity: 60</p>
                                                        {/* <p>Discount: 6%</p> */}
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="divider-line"></div>
                                        <div className="items-extra-detail">
                                            <p>To Deliver: 500</p>
                                            <p>Not Delivered: 200</p>
                                        </div> */}
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal open={openPriorityModal} footer={null} onCancel={() => setOpenPriorityModal(false)}>
                <div className="priorities-modal">
                    <h3>Set Order Priority</h3>
                    {/* <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p> */}
                    <div className="mt_7">
                        <form>
                            <div className="form-group">
                                <label>Order ID</label>
                                <Controller name="quantity" defaultValue={null} control={control}
                                    render={({ field }) => (
                                        <Input {...field} name="quantity" disabled value="JIE2EINI322" style={{ height: '4.5rem' }} />
                                    )} />
                            </div>
                            <div className="form-group">
                                <label>Select Option</label>
                                <div>
                                    <Radio.Group
                                        options={radioOptions}
                                        onChange={onChangePriorityOption}
                                        value={orderPriority}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </div>
                            </div>
                        </form>
                        {
                            orderPriority === "return" ?
                                <div className="mt_5">
                                    <label>Upload Evidence</label>
                                    <div className="banner-design"></div>
                                </div> : ""
                        }
                        <div className="mt_7">
                            <button
                                onClick={() => setOpenPriorityModal(false)}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={openReturnsModal} footer={null} onCancel={() => setOpenReturnsModal(false)}>
                <div className="priorities-modal">
                    <h3>Return Orders</h3>
                    {/* <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p> */}
                    <div className="mt_7">
                        <form>
                            <div className="form-group">
                                <label>Quantity Delivered</label>
                                <Controller name="quantity" defaultValue={null} control={control}
                                    render={({ field }) => (
                                        <Input {...field} name="quantity" style={{ height: '4.5rem' }} />
                                    )} />
                            </div>
                        </form>
                        <div className="form-group">
                            <label>Reason for Return</label>
                            <div>
                                <Radio.Group
                                    options={returnsOptions}
                                    onChange={onChangeReturnsOption}
                                    value={returnsReason}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="mt_7">
                            <button
                                onClick={() => setOpenReturnsModal(false)}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={paymentOptionModal} footer={null} onCancel={() => setPaymentOptionModal(false)}>
                <div className="priorities-modal">
                    <h3>Select Payment Option</h3>
                    <div className="mt_7">
                        <form>
                            <div className="form-group">
                                <label>Amount to Pay</label>
                                <Controller name="quantity" defaultValue={null} control={control}
                                    render={({ field }) => (
                                        <Input {...field} name="quantity" disabled value="NGN 10,000.00" style={{ height: '4.5rem' }} />
                                    )} />
                            </div>
                        </form>
                        <div className="form-group">
                            <label>Payment Option</label>
                            <div>
                                <Radio.Group
                                    options={paymentOptions}
                                    onChange={onChangeReturnsOption}
                                    value={userPaymentOptions}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="mt_7">
                            <button
                                onClick={() => setPaymentOptionModal(false)}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default DeliveryDetails;