import React, { useState } from "react";

import { ReactComponent as MenuIcon } from "../assets/images/menu.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/images/arrow-right.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/images/arrow-left-circle.svg";

import { Link, useNavigate } from "react-router-dom";
import { Modal, Input } from "antd";
import { Controller, useForm } from "react-hook-form";
import 'swiper/css';
import Footer from "../components/footer";

const DeliveriesPage = () => {

    const Navigate = useNavigate();
    const { handleSubmit, control } = useForm({});
    const [openValidationModal, setOpenValidationModal] = useState(false);

    const breakpoints = {
        0: {
            slidesPerView: 2.1
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    return (
        <div className="app-data">
            <div className="app-display">
                <div className="brown-bg">
                    <div className="contain">
                        <div className="app-display-header">
                            <h3 className="page-title"><ArrowLeftIcon /> Delivery History</h3>
                            <div>
                                <BellIcon className="bell-icon" />
                            </div>
                        </div>
                        <div className="app-data-filter">
                            <ul>
                                <li className="active-filter">Today</li>
                                <li>Week</li>
                                <li>Month</li>
                                <li>All History</li>
                            </ul>
                        </div>
                        <div className="app-data-summary">
                            <div className="grid-2">
                                <div className="summary-box _1">
                                    <h4>18</h4>
                                    <p>Buyers</p>
                                </div>
                                <div className="summary-box _2">
                                    <h4>22</h4>
                                    <p>Items</p>
                                </div>
                                <div className="summary-box _3">
                                    <h4>34</h4>
                                    <p>Quantities</p>
                                </div>
                                <div className="summary-box _4">
                                    <h4>81kg</h4>
                                    <p>Weight</p>
                                </div>
                            </div>
                        </div>
                        {/* <button className="deliveries btn btn-black">Report a Receiver</button> */}
                    </div>
                </div>
                <div className="data-delivery-details mt_10">
                    <div className="contain">
                        <h2>All Deliveries</h2>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-detail">
                            <div className="data-detail-header">
                                <div className="data-header-block">
                                    <div className="header-icon-cover">
                                        <div className="header-icon-box"></div>
                                        <div>
                                            <p className="muted">ID number</p>
                                            <h4>JIE2EINI322</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <h5>Ikotun, Lagos State</h5>
                                        <p className="delivery-status">processing</p>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="receiver-detail">
                                    <div>
                                        <h5>Adeleke Ifeoluwase</h5>
                                        <p>08132277316</p>
                                    </div>
                                    <div>
                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal open={openValidationModal} footer={null} onCancel={() => setOpenValidationModal(false)}>
                <div className="priorities-modal">
                    <h3>Verify Pin</h3>
                    {/* <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p> */}
                    <div className="mt_7">
                        <div>
                            <label>Enter Transaction Pin</label>
                            <Controller control={control} name="pin" defaultValue={null}
                                render={({ field }) => (
                                    <Input {...field} name="pin" style={{ height: '4.5rem' }} />
                                )} />
                        </div>
                        <div className="mt_7">
                            <button
                                onClick={() => Navigate("/delivery-detail")}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DeliveriesPage;