import "../../assets/css/auth.css";

import { Drawer, Input, Spin, message, Upload, Select } from "antd";
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ReactComponent as UploadIcon } from "../../assets/images/icons/upload.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import AuthBanner from "../../assets/images/illustrations/auth_1.jpg";
import { _fetch_driver_organization, _sign_driver_up } from "../../common/axios_services";
import { yupResolver } from "@hookform/resolvers/yup";
import AppRoutes from "../../common/routes";

const SignUpPage = () => {

    const digitsOnly = (value) => /^\d+$/.test(value);
    let signUpValidation = yup.object().shape({
        first_name: yup.string().required("First name is required").min(2, "First name must be more than 2 characters"),
        last_name: yup.string().required("Last name is required").min(2, "Last name must be more than 2 characters"),
        email_address: yup.string().email().required("Email address is required").min(2, "Email address must be more than 2 characters"),
        phone_number: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly),
        password: yup.string().required("Password is required").min(6, "Password must be more than 6 characters"),
        organization: yup.string().required("Please select an organization")
    })

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(signUpValidation)
    });

    const navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const [appLoading, setAppLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [organizationData, setOrganizationData] = useState([]);

    const [extraUserDetails, setExtraUserDetails] = useState({
        firstName: "", last_name: "", email_address: "", phone_number: "", password: ""
    });

    const saveUserBasicInfo = async e => {
        setAppLoading(true);
        setErrorMessage("");
        try {
            let userSignup = await _sign_driver_up({
                app: "mobile",
                first_name: e.first_name,
                last_name: e.last_name,
                email: e.email_address,
                phone_number: e.phone_number,
                password: e.password,
                confirm_password: e.password,
                organisation_id: e.organization,
                role_id: 3
            });
            if (userSignup?.data?.status === "error") {
                return setErrorMessage(userSignup.data.message);
            };
            localStorage.setItem('konnectnewaccountcreated', 'true');
            navigate(AppRoutes.verify_email);
        } catch (err) {
            if (err?.response?.data?.message) {
                setErrorMessage(err?.response?.data?.message)
            } else if (err?.code === "ERR_NETWORK") {
                setErrorMessage('An error occurred while signing user up. Please check network and try again');
            } else {
                setErrorMessage('An error occurred while signing user up. Please try again');
            }
            setAppLoading(false);
        }
    }

    const completeUserSignUp = async e => {
        setAppLoading(true);

        try {
            let userSignup = await _sign_driver_up({
                first_name: e.first_name,
                last_name: e.last_name,
                email: e.email_address,
                phone_number: e.phone_number,
                password: e.password,
                confirm_password: e.password,
                organisation_id: e.organization
            });
            if (userSignup?.data?.status === "error") {
                return setErrorMessage(userSignup.data.message);
            };
            localStorage.setItem('konnectnewaccountcreated', 'true');
            navigate('/verify-email');
        } catch (err) {
            if (err?.response?.data?.message === "Looks like you already have an account. Email already exist. Check your input and try again.") {
                setErrorMessage('Email already exist. Please check your input and try again.')
            } else if (err?.response?.data?.message === "Looks like you already have an account. Phone number already exist. Check your input and try again.") {
                setErrorMessage('Phone number already exist. Please check your input and try again.')
            } else if (err?.response?.data?.message === "Phone number must be shorter than or equal to 11 characters. Check your input and try again.") {
                setErrorMessage('Phone number must be shorter than or equal to 11 characters. Please Check your input and try again.')
            } else if (err?.code === "ERR_NETWORK") {
                setErrorMessage('An error occurred while signing user up. Please check network and try again');
            } else {
                setErrorMessage('An error occurred while signing user up. Please try again');
            }
            setAppLoading(false);
        }
    }

    const fetchUserOrganization = async () => {
        try {
            let userData = await _fetch_driver_organization();
            setOrganizationData(userData.data.data);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                setErrorMessage('An error occurred while fetching organization. Please check network and try again');
            } else {
                setErrorMessage('An error occurred while fetching organization. Please try again');
            }
        }
    }

    useEffect(() => {
        fetchUserOrganization();
    }, [])

    return (
        <div className="signin-page">
            <div
                className={`top-bar contain mt_1`}>
                <div className="grid-flex top-welcome-address">
                    <div onClick={() => navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="contain auth-content">
                {/* {
                    currentFormDisp === "basic" ?
                        <> */}
                <form onSubmit={handleSubmit(saveUserBasicInfo)}>
                    <div>
                        <h3>Create a free account</h3>
                    </div>
                    {
                        errorMessage.length ?
                            <p className="form-error"
                                style={{ marginBottom: 30, display: "block" }}>{errorMessage}</p>
                            : ""
                    }
                    <div className="grid-2 mt_2">
                        <div className="form_group">
                            <label htmlFor="first_name">First name</label>
                            <Controller name="first_name" control={control}
                                render={({ field }) => (
                                    <Input name="first_name" {...field} id="first_name"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.first_name && <p className="form-error">{errors.first_name.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="last_name">Last name</label>
                            <Controller name="last_name" control={control}
                                render={({ field }) => (
                                    <Input name="last_name" {...field} id="last_name"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.last_name && <p className="form-error">{errors.last_name.message}</p>}
                        </div>
                    </div>
                    <div className="form-flex-2">
                        <div className="form_group">
                            <label htmlFor="email_address">Email address</label>
                            <Controller name="email_address" control={control}
                                render={({ field }) => (
                                    <Input name="email_address" type="email" {...field} id="email_address"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.email_address && <p className="form-error">{errors.email_address.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="phone_number">Phone number</label>
                            <Controller name="phone_number" control={control}
                                render={({ field }) => (
                                    <Input name="phone_number" {...field} id="phone_number"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.phone_number && <p className="form-error">{errors.phone_number.message}</p>}
                        </div>
                    </div>
                    <div className="form_group">
                        <label htmlFor="password">Password</label>
                        <Controller name="password" control={control}
                            render={({ field }) => (
                                <Input.Password name="password" {...field} id="password"
                                    style={{ height: "5rem" }} />
                            )} />
                        {errors.password && <p className="form-error">{errors.password.message}</p>}
                    </div>
                    <div className="form_group">
                        <label htmlFor="organization">Organization</label>
                        <Controller name="organization" control={control}
                            render={({ field }) => (
                                <Select name="organization" {...field} id="organization"
                                    style={{ width: "100%", display: "block", height: "5rem" }}>
                                    {
                                        organizationData.map((organization, index) => (
                                            <Select.Option value={organization.id} key={index}>{organization.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )} />
                        {errors.organization && <p className="form-error">{errors.organization.message}</p>}
                    </div>
                    <div className="welcome-buttons">
                        <div className="welcome-buttons-content">
                            {
                                !appLoading ?
                                    <button className="btn-base">Create an account now</button>
                                    :
                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                            }
                        </div>
                    </div>
                </form>
                {/* : currentFormDisp === "extra" ?
                            <>
                                <form onSubmit={submitExtraInfo(saveUserExtraInfo)}>
                                    <div>
                                        <h3>Create a free account</h3>
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="nin">Your NIN</label>
                                        <Controller name="nin" control={controlExtraInfo}
                                            render={({ field }) => (
                                                <Input name="nin" {...field} id="nin"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="grid-2">
                                        <div className="form_group">
                                            <label htmlFor="state">State Of Residence</label>
                                            <Controller name="state" control={controlExtraInfo}
                                                render={({ field }) => (
                                                    <Input name="state" {...field} id="state"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="lga">Local Government</label>
                                            <Controller name="lga" control={controlExtraInfo}
                                                render={({ field }) => (
                                                    <Input name="lga" {...field} id="lga"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="grid-2">
                                        <div className="form_group">
                                            <label htmlFor="driver_license_no">Driving License Number</label>
                                            <Controller name="driver_license_no" control={controlExtraInfo}
                                                render={({ field }) => (
                                                    <Input name="driver_license_no" {...field} id="driver_license_no"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="year_of_experience">Years of Experience</label>
                                            <Controller name="year_of_experience" control={controlExtraInfo}
                                                render={({ field }) => (
                                                    <Input name="year_of_experience" {...field} id="year_of_experience"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="profilePic">Upload Profile Picture</label>
                                        <Dragger multiple={false}
                                            onChange={handleProfilePicUpload}
                                        // {...props}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <UploadIcon />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="drivingLicense">Upload Your Driving License</label>
                                        <Dragger multiple={false}
                                            onChange={handleUserIDUpload}
                                        // {...props}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <UploadIcon />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </div>
                                    <div className="welcome-buttons">
                                        <div className="welcome-buttons-content">
                                            {
                                                !appLoading ?
                                                    <button className="btn-base">Provide Next of Kin Details</button>
                                                    :
                                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </>
                            :
                            <div>
                                {errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}
                                <form onSubmit={submitNOKDetails(completeUserSignUp)}>
                                    <div>
                                        <h3>Next of Kin Details</h3>
                                    </div>
                                    <div className="grid-2">
                                        <div className="form_group">
                                            <label htmlFor="firstName">First name</label>
                                            <Controller name="firstName" control={controlNOKDetails}
                                                render={({ field }) => (
                                                    <Input name="firstName" {...field} id="firstName"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="last_name">Last name</label>
                                            <Controller name="last_name" control={controlNOKDetails}
                                                render={({ field }) => (
                                                    <Input name="last_name" {...field} id="last_name"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="phone_number">Phone number</label>
                                        <Controller name="phone_number" control={controlNOKDetails}
                                            render={({ field }) => (
                                                <Input name="phone_number" {...field} id="phone_number"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="password">Address</label>
                                        <Controller name="password" control={controlNOKDetails}
                                            render={({ field }) => (
                                                <Input.TextArea rows={5} name="password" {...field} id="password" />
                                            )} />
                                    </div>
                                    <div className="welcome-buttons">
                                        <div className="welcome-buttons-content">
                                            {
                                                !appLoading ?
                                                    <button className="btn-base">Create an account now</button>
                                                    :
                                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                } */}
                <div>
                    <Link to="/signin"><span>Have an account? Sign In Here</span></Link>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage;