import "./assets/css/index.css";
import React, { useState } from "react";
import { Provider } from 'react-redux';
import store from './store';

import { BrowserRouter, Route, Routes } from "react-router-dom";

// import WelcomePage from "./page/welcome";
import Dashboard from "./pages/dashboard";
import Dash from "./pages/dash";
import Deliveries from "./pages/deliveries";
import SignInPage from "./pages/auth/signin";
import SignUpPage from "./pages/auth/signup";

import PickupDetails from "./pages/pickup-details";
import Settings from "./pages/settings";
import DeliveryDetails from "./pages/detail-delivery";
import PickupPage from "./pages/pickup";
import Disputes from "./pages/disputes";
import VerificationPage from "./pages/auth/verification";
import WelcomePage from "./pages/welcome";
import OnSitePage from "./pages/actions/onsite";

const App = () => {

    return (
        <Provider store={store}>
            <div className="page-content">
                <BrowserRouter>
                    <Routes>
                        {/* <Route path="/" element={<WelcomePage />} /> */}
                        {/* <Route path="/welcome" element={<WelcomePage />} /> */}
                        <Route path="/" element={<WelcomePage />} />
                        <Route path="/on-site" element={<OnSitePage />} />

                        <Route path="/signin" element={<SignInPage />} />
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/verify-email" element={<VerificationPage />} />
                        <Route path="/home" element={<Dashboard />} />
                        <Route path="/homie" element={<Dash />} />
                        <Route path="/deliveries" element={<Deliveries />} />
                        <Route path="/delivery-detail" element={<DeliveryDetails />} />
                        <Route path="/pickup" element={<PickupPage />} />
                        <Route path="/pickup-detail" element={<PickupDetails />} />
                        <Route path="/disputes" element={<Disputes />} />
                        {/* <Route path="/k" element={<Dashboar />} /> */}
                        <Route path="/settings" element={<Settings />} />

                        {/* <Route path="/buyers" element={<Buyers />} />
                <Route path="/buyer/:2" element={<BuyerDetail />} />
                <Route path="/pickups" element={<Pickup />} />
                <Route path="/pickup/:id" element={<PickupDetail />} /> */}
                    </Routes>
                </BrowserRouter>
            </div>
        </Provider>
    )
}
export default App;