import React, { useState } from "react";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ReactComponent as ArrowLeftIcon } from "../assets/images/arrow-left-circle.svg";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as LocationIcon } from "../assets/images/map-pin.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";

import { Modal, Tabs, Radio, Input, Collapse } from "antd";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { Controller, useForm } from "react-hook-form";

const Disputes = () => {

    const breakpoints = {
        0: {
            slidesPerView: 2.2
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }


    const { handleSubmit, control } = useForm({});
    const [orderPriority, setOrderPriority] = useState('next');
    const [returnsReason, setReturnsReason] = useState('unavailable');
    const [openPriorityModal, setOpenPriorityModal] = useState(false);
    const [openReturnsModal, setOpenReturnsModal] = useState(false);

    const onChangePriorityOption = ({ target: { value } }) => {
        setOrderPriority(value);
    };

    const onChangeReturnsOption = ({ target: { value } }) => {
        setReturnsReason(value);
    };

    const radioOptions = [{
        label: 'Return',
        value: 'return',
    },
    {
        label: 'Next',
        value: 'next',
    },
    {
        label: 'Arrive',
        value: 'arrive',
    }]

    const returnsOptions = [{
        label: 'Unavailable',
        value: 'unavailable',
    },
    {
        label: 'Damaged',
        value: 'damaged',
    }]

    return (
        <div className="app-data inner-detail disputes">
            <div className="app-display">
                <div className="contain">
                    <div className="app-display-header">
                        <h3 className="page-title"><ArrowLeftIcon /> Disputes</h3>
                        <div>
                            <BellIcon className="bell-icon" />
                        </div>
                    </div>
                </div>
                <div className="divider-line"></div>
                {/* <div className="map-box">
                    <div></div>
                </div> */}
            </div>
            <div className="extra-info-content">
                <div className="contain">
                    <div>
                        <div className="items-list">
                            <Collapse defaultActiveKey={[0]}>
                                <Collapse.Panel showArrow={false} key={0}
                                    header={<p style={{ display: 'inline' }}>Adeleke Ifeoluwase - 6 items</p>}>
                                    <div>
                                        <ul className="dispute-detail">
                                            <li><span>Name of Buyer:</span><span>Adeleke Ifeoluwase</span></li>
                                            <li><span>Location:</span><span>Ikotun, Lagos State</span></li>
                                            <li><span>Items in Dispute:</span><span>Honeywell Noodles</span></li>
                                            <li><span>Quantity in Dispute:</span><span>4</span></li>
                                            <li><span>Reason for Dispute:</span><span>unavailable</span></li>
                                        </ul>
                                    </div>
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} key={1}
                                    header={<p style={{ display: 'inline' }}>Adeleke Ifeoluwase - 6 items</p>}>
                                    <div>
                                        <ul className="dispute-detail">
                                            <li><span>Name of Buyer:</span><span>Adeleke Ifeoluwase</span></li>
                                            <li><span>Location:</span><span>Ikotun, Lagos State</span></li>
                                            <li><span>Items in Dispute:</span><span>Honeywell Noodles</span></li>
                                            <li><span>Quantity in Dispute:</span><span>4</span></li>
                                            <li><span>Reason for Dispute:</span><span>unavailable</span></li>
                                        </ul>
                                    </div>
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} key={2}
                                    header={<p style={{ display: 'inline' }}>Adeleke Ifeoluwase - 6 items</p>}>
                                    <div>
                                        <ul className="dispute-detail">
                                            <li><span>Name of Buyer:</span><span>Adeleke Ifeoluwase</span></li>
                                            <li><span>Location:</span><span>Ikotun, Lagos State</span></li>
                                            <li><span>Items in Dispute:</span><span>Honeywell Noodles</span></li>
                                            <li><span>Quantity in Dispute:</span><span>4</span></li>
                                            <li><span>Reason for Dispute:</span><span>unavailable</span></li>
                                        </ul>
                                    </div>
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} key={3}
                                    header={<p style={{ display: 'inline' }}>Adeleke Ifeoluwase - 6 items</p>}>
                                    <div>
                                        <ul className="dispute-detail">
                                            <li><span>Name of Buyer:</span><span>Adeleke Ifeoluwase</span></li>
                                            <li><span>Location:</span><span>Ikotun, Lagos State</span></li>
                                            <li><span>Items in Dispute:</span><span>Honeywell Noodles</span></li>
                                            <li><span>Quantity in Dispute:</span><span>4</span></li>
                                            <li><span>Reason for Dispute:</span><span>unavailable</span></li>
                                        </ul>
                                    </div>
                                </Collapse.Panel>
                                <Collapse.Panel showArrow={false} key={4}
                                    header={<p style={{ display: 'inline' }}>Adeleke Ifeoluwase - 6 items</p>}>
                                    <div>
                                        <ul className="dispute-detail">
                                            <li><span>Name of Buyer:</span><span>Adeleke Ifeoluwase</span></li>
                                            <li><span>Location:</span><span>Ikotun, Lagos State</span></li>
                                            <li><span>Items in Dispute:</span><span>Honeywell Noodles</span></li>
                                            <li><span>Quantity in Dispute:</span><span>4</span></li>
                                            <li><span>Reason for Dispute:</span><span>unavailable</span></li>
                                        </ul>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                            {/* <div className="item-single-list">
                                <div className="items-name-detail">
                                    <div>
                                        <div className="img-cover"></div>
                                    </div>
                                    <div className="item-name">
                                        <div>
                                            <h4>Honeywell Noodles</h4>
                                            <div className="grid-flex">
                                                <p>Delivered: 60</p>
                                                <p>Returned: 6</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-line"></div>
                                <div className="grid-flex dispute-author">
                                    <div>
                                        <h3>Adeleke Ifeoluwase</h3>
                                        <h4>Ikotun, Lagos State</h4>
                                    </div>
                                    <p>Unavailable</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Disputes;