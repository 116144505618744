import React, { useState } from "react";

import { ReactComponent as HomeIcon } from "../assets/images/home-cropped.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings-cropped.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";
import { ReactComponent as CartIcon } from "../assets/images/shopping-cart.svg";
import { ReactComponent as ShoppingBagIcon } from "../assets/images/shopping-bag-cropped.svg";
import { NavLink } from "react-router-dom";

const Footer = props => {
    return (
        <div className="footer">
            <div>
                <NavLink to={`/`} className={({ isActive }) => isActive ? 'active-footer' : ''}>
                    <HomeIcon />
                    <h4>Home</h4>
                </NavLink>
            </div>
            <div>
                <NavLink to={`/deliveries`} className={({ isActive }) => isActive ? 'active-footer' : ''}>
                    <CartIcon />
                    <h4>Deliveries</h4>
                </NavLink>
            </div>
            <div>
                <NavLink to={`/disputes`} className={({ isActive }) => isActive ? 'active-footer' : ''}>
                    <ShoppingBagIcon />
                    <h4>Disputes</h4>
                </NavLink>
            </div>
            <div>
                <NavLink to={`/settings`} className={({ isActive }) => isActive ? 'active-footer' : ''}>
                    <SettingsIcon />
                    <h4>Settings</h4>
                </NavLink>
            </div>
        </div>
    )
}

export default Footer;