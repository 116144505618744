
import React, { useState } from "react";

import { ReactComponent as MenuIcon } from "../assets/images/menu.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/images/arrow-right.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings-cropped.svg";
import { ReactComponent as ShoppingBagIcon } from "../assets/images/shopping-bag-cropped.svg";
import { ReactComponent as LogOutIcon } from "../assets/images/log-out-cropped.svg";
import { ReactComponent as CartIcon } from "../assets/images/shopping-cart.svg";

import _1 from "../assets/images/_2.jpg";

import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import Footer from "../components/footer";

import { Modal, Tabs, Radio, Input } from "antd";
import { Controller, useForm } from "react-hook-form";

const Dashboard = () => {

    const Navigate = useNavigate();
    const { handleSubmit, control } = useForm({});
    const [returnsReason, setReturnsReason] = useState("none");
    const [openReturnsModal, setOpenReturnsModal] = useState(false);
    const [openValidationModal, setOpenValidationModal] = useState(false);

    const [currDisplay, setCurrDisplay] = useState("pickup");

    const breakpoints = {
        0: {
            slidesPerView: 2.1
        },
        600: {
            slidesPerView: 4.2
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    const onChangeReturnsOption = ({ target: { value } }) => {
        setReturnsReason(value);
    };

    const returnsOptions = [{
        label: 'N/A',
        value: 'none',
    }, {
        label: 'Unavailable',
        value: 'unavailable',
    },
    {
        label: 'Damaged',
        value: 'damaged',
    }]

    return (
        <div className="app-data dashboard">
            <div className="app-display">
                <div className="colored-bg">
                    <div className="contain">
                        <div className="driver-settings-name">
                            <div>
                                <div className="blank-avatar"></div>
                            </div>
                            <div>
                                <h2>Hello Ifeoluwase,</h2>
                                <p>What would you like to start with?</p>
                            </div>
                        </div>
                        <div className="dashboard-summary mt_5">
                            <div className="app-data-summary">
                                <div className="grid-2">
                                    <div className="summary-box _1">
                                        <h4>18</h4>
                                        <p>Buyers</p>
                                    </div>
                                    <div className="summary-box _2">
                                        <h4>22</h4>
                                        <p>Items</p>
                                    </div>
                                    <div className="summary-box _3">
                                        <h4>34</h4>
                                        <p>Quantities</p>
                                    </div>
                                    <div className="summary-box _4">
                                        <h4>81kg</h4>
                                        <p>Weight</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-4">
                                <div className="_1">
                                    <div className="icon-cover">
                                        <SettingsIcon />
                                    </div>
                                    <h4>Profile</h4>
                                </div>
                                <div className="_2">
                                    <div className="icon-cover">
                                        <CartIcon />
                                    </div>
                                    <h4>Deliveries</h4>
                                </div>
                                <div className="_3">
                                    <div className="icon-cover">
                                        <ShoppingBagIcon />
                                    </div>
                                    <h4>Pickups</h4>
                                </div>
                                <div className="_4">
                                    <div className="icon-cover">
                                        <LogOutIcon />
                                    </div>
                                    <h4>Log Out</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt_10 inner-detail disputes">
                    <div className="inner-title">
                        <div
                            onClick={() => setCurrDisplay('pickup')}
                            className={`${currDisplay === "pickup" ? "active" : ""} title-list`}>
                            <h2>Today's Pickups</h2>
                        </div>
                        <div
                            onClick={() => setCurrDisplay('deliveries')}
                            className={`${currDisplay === "deliveries" ? "active" : ""} title-list`}>
                            <h2>Today's Deliveries</h2>
                        </div>
                    </div>
                    <div className="contain">
                        {
                            currDisplay === "pickup" ?
                                <div className="data-delivery-details">
                                    <div className="items-list">
                                        <div className="item-single-list">
                                            <div className="items-name-detail">
                                                <div>
                                                    <div className="img-cover"></div>
                                                </div>
                                                <div className="item-name">
                                                    <div>
                                                        <div className="grid-fle">
                                                            <div>
                                                                <h4>Honeywell Noodles</h4>
                                                                <div className="grid-flex">
                                                                    <p>Ordered: 60</p>
                                                                    <p>Received: &mdash;</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-single-list">
                                            <div className="items-name-detail">
                                                <div>
                                                    <div className="img-cover"></div>
                                                </div>
                                                <div className="item-name">
                                                    <div>
                                                        <div className="grid-fle">
                                                            <div>
                                                                <h4>Honeywell Noodles</h4>
                                                                <div className="grid-flex">
                                                                    <p>Ordered: 60</p>
                                                                    <p>Received: &mdash;</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item-single-list">
                                            <div className="items-name-detail">
                                                <div>
                                                    <div className="img-cover"></div>
                                                </div>
                                                <div className="item-name">
                                                    <div>
                                                        <div className="grid-fle">
                                                            <div>
                                                                <h4>Honeywell Noodles</h4>
                                                                <div className="grid-flex">
                                                                    <p>Ordered: 60</p>
                                                                    <p>Received: &mdash;</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button onClick={() => setOpenReturnsModal(true)}>Action</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="data-delivery-details">
                                    <div className="items-list">
                                        <div className="data-detail">
                                            <div className="data-detail-header">
                                                <div className="data-header-block">
                                                    <div className="header-icon-cover">
                                                        <div className="header-icon-box"></div>
                                                        <div>
                                                            <p className="muted">ID number</p>
                                                            <h4>JIE2EINI322</h4>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5>Ikotun, Lagos State</h5>
                                                        <p className="delivery-status">processing</p>
                                                    </div>
                                                </div>
                                                <div className="divider-line"></div>
                                                <div className="receiver-detail">
                                                    <div>
                                                        <h5>Adeleke Ifeoluwase</h5>
                                                        <p>08132277316</p>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data-detail">
                                            <div className="data-detail-header">
                                                <div className="data-header-block">
                                                    <div className="header-icon-cover">
                                                        <div className="header-icon-box"></div>
                                                        <div>
                                                            <p className="muted">ID number</p>
                                                            <h4>JIE2EINI322</h4>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5>Ikotun, Lagos State</h5>
                                                        <p className="delivery-status">processing</p>
                                                    </div>
                                                </div>
                                                <div className="divider-line"></div>
                                                <div className="receiver-detail">
                                                    <div>
                                                        <h5>Adeleke Ifeoluwase</h5>
                                                        <p>08132277316</p>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="data-detail">
                                            <div className="data-detail-header">
                                                <div className="data-header-block">
                                                    <div className="header-icon-cover">
                                                        <div className="header-icon-box"></div>
                                                        <div>
                                                            <p className="muted">ID number</p>
                                                            <h4>JIE2EINI322</h4>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5>Ikotun, Lagos State</h5>
                                                        <p className="delivery-status">processing</p>
                                                    </div>
                                                </div>
                                                <div className="divider-line"></div>
                                                <div className="receiver-detail">
                                                    <div>
                                                        <h5>Adeleke Ifeoluwase</h5>
                                                        <p>08132277316</p>
                                                    </div>
                                                    <div>
                                                        <button onClick={() => setOpenValidationModal(true)}>View Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Modal open={openReturnsModal} footer={null} onCancel={() => setOpenReturnsModal(false)}>
                <div className="priorities-modal">
                    <h3>Change Order Priority</h3>
                    {/* <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p> */}
                    <div className="mt_7">
                        <form>
                            <div className="form-group">
                                <label>Item</label>
                                <Controller name="itemName" defaultValue={null} control={control}
                                    render={({ field }) => (
                                        <Input {...field} name="quantity"
                                            disabled value="Honeywell Noodles"
                                            style={{ height: '4.5rem' }} />
                                    )} />
                            </div>
                            <div className="form-flex-2">
                                <div className="form-group">
                                    <label>Quantity ordered</label>
                                    <Controller name="quantity" defaultValue={null} control={control}
                                        render={({ field }) => (
                                            <Input {...field} name="quantity" value={60} disabled style={{ height: '4.5rem' }} />
                                        )} />
                                </div>
                                <div className="form-group">
                                    <label>Quantity available</label>
                                    <Controller name="quantity" defaultValue={null} control={control}
                                        render={({ field }) => (
                                            <Input {...field} name="quantity" style={{ height: '4.5rem' }} />
                                        )} />
                                </div>
                            </div>
                        </form>
                        <div className="form-group">
                            <label>Reason</label>
                            <div>
                                <Radio.Group
                                    options={returnsOptions}
                                    onChange={onChangeReturnsOption}
                                    value={returnsReason}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        <div className="mt_7">
                            <button
                                onClick={() => setOpenReturnsModal(false)}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={openValidationModal} footer={null} onCancel={() => setOpenValidationModal(false)}>
                <div className="priorities-modal">
                    <h3>Change Order Priority</h3>
                    {/* <p>The difference from Select is that Radio is visible to the user and can
                        facilitate the comparison of choice, which means there shouldn't be too
                        many of them.</p> */}
                    <div className="mt_7">
                        <div>
                            <label>Enter Transaction Pin</label>
                            <Controller control={control} name="pin" defaultValue={null}
                                render={({ field }) => (
                                    <Input {...field} name="pin" style={{ height: '4.5rem' }} />
                                )} />
                        </div>
                        <div className="mt_7">
                            <button
                                onClick={() => Navigate("/delivery-detail")}
                                className="btn btn-green">Continue</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </div >
    )
}

export default Dashboard;