
import { Input, Spin, notification } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
// import AppRoutes from '../../common/routes';

import { _verify_user_email_signup } from '../../common/axios_services';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";
import AppRoutes from '../../common/routes';


const VerificationPage = () => {

    const Navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);

    const signInCheck = yup.object().shape({
        email: yup.string().email('Email address is not valid').required('Email address field is required'),
        pass_code: yup.string().min(3, "Pass code can not be less than 5 characters").required('Verification code is required').max(5, "Pass code can not be more than 5 characters"),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            email: "",
            password: "",
        },
        resolver: yupResolver(signInCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };


    const verifyUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { email, pass_code } = e;
        try {
            const response = await _verify_user_email_signup({
                email,
                pass_code
            });
            openNotificationWithIcon("success", "Email address verified successfully");
            Navigate(AppRoutes.signin);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    return (
        <div className="signin-page verification_page">
            <div>
                <div className="small-page-title grid-flex">
                    <div onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div>
                        <h3 className="auth-sect-title">Verify email by passcode</h3>
                        <p>We have sent a verification code to your email address and phone number. Please enter the verification code and re-enter your email address to
                            complete your signup process.</p>
                    </div>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    <form onSubmit={handleSubmit(verifyUser)}>
                        <div className="form_group">
                            <label htmlFor="email">Email address</label>
                            <Controller name="email" control={control}
                                render={({ field }) => (
                                    <Input name="email" {...field} id="email" type='"email'
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.email && <p className="form-error">{errors.email.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="pass_code">Verification Code</label>
                            <Controller name="pass_code" control={control}
                                render={({ field }) => (
                                    <Input name="pass_code" type="tel" {...field} id="pass_code"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.pass_code && <p className="form-error">{errors.pass_code.message}</p>}
                        </div>
                        <div className="welcome-buttons">
                            <div className="welcome-buttons-content">
                                <div>
                                    {
                                        !fetchingData ?
                                            <button className="btn-base">Verify Passcode</button>
                                            :
                                            <button disabled className="btn-base"><Spin indicator={antIcon} /></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default VerificationPage;