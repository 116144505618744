import Jwt_decrypt from "./Jwt_decrypt";
import axiosCall from "./axios_call";

const signIn = async (data) => {
    return axiosCall.post('/auth/login', data)
}

export const _sign_driver_up = async (data) => {
    return axiosCall.post('/auth/sign-up/driver', data)
}

export const _fetch_driver_organization = async () => {
    return axiosCall('/merchants/logistic')
}

export const _fetch_van_types = async () => {
    return axiosCall('/van-type/driver')
}

export const _submit_driver_onsite_data = async e => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token });
    return axiosCall.post('/delivery-drivers/request', e, {
        headers: { "Authorization": `Bearer ${token}` }
    })
}

export const _check_delivery_date = async () => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token });
    return axiosCall('/delivery-date/is_today', { headers: { "Authorization": `Bearer ${token}` } })
}

export const _verify_user_email_signup = async e => {
    return axiosCall.post('/auth/confirm-email/pass_code', e)
}

const pay_checkout = async ({ data, token }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post('/transactions/initiate_payments', data, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.post('/transactions/initiate_payments', data, { headers: { "Authorization": "Bearer" + "" } })
    }
}

const _get_profile = async () => {
    const jwt_token = localStorage.konnect_token
    const appRole = "user"
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

const _get_all_referrals = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_transaction_data = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transactions/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transactions/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_all_account_details = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/account/details?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/account/details?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _confirm_user_transfer_pin = async pin => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin/user/confirm-pin/${pin}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user/confirm-pin/${pin}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_user_wallet_history = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_saved_address = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delevery-addresses/user`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delevery-addresses/user`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_delivery_address = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delevery-addresses`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delevery-addresses`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _bank_verification = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/banks_account/verification`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/banks_account/verification`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _wallet_verification = async ({ ref }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _payout = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/initiat`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/initiat`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delivery_info = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delivery-fees/shop_order`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delivery-fees/shop_order`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_password = async ({ user_id, current_password, new_password, confirm_new_password }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/change-password/${user_id}`, { current_password, new_password, confirm_new_password }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/change-password/${user_id}`, { current_password, new_password, confirm_new_password }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_profile = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/auth/profile`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/auth/profile`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_2fa_verification = async ({ value }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/change_login_2fa`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/change_login_2fa`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_2fa = async ({ otp }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_trans_pin = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/auth/change-transaction-pin`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/auth/change-transaction-pin`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _calc_commission = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/rate-settings/transaction_fees`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/rate-settings/transaction_fees`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _wise9ja_topup = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transactions/initiate_payments`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transactions/initiate_payments`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _rate_setting_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/rate-settings/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/rate-settings/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _generate_otp = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/generate_otp`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/generate_otp`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_banks = async ({ code }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transfer/banks/${code}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transfer/banks/${code}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_otp = async ({ otp }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_state = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/states/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/states/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_search_product = async ({ page, page_size, search }) => {
    return axiosCall(`/products?page=${page}&per_page=${page_size}&search=${search}`)
}

const _get_all_cities = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_pickup_locations = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/locations/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/locations/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_selected_delivery_location = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delevery-addresses/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delevery-addresses/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_selected_pickup_location = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/locations/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/locations/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_invoice = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/invoice/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/invoice/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_orders = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_wise9ja_payment = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/wisenija-subs`, e, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/wisenija-subs`, e, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _fetch_wise9ja_plans = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/wisenija-subs/generate_sub_plan`, e, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/wisenija-subs/generate_sub_plan`, e, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wise9ja_data = async ({ page_no, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs/user?page=${page_no}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs/user?page=${page_no}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_wallet_data = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wallet_data = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/account_details`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/account_details`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_dashboard_data = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/account_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/account_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wisenija_history = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_bank_list = async country => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transfer/banks/${country}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transfer/banks/${country}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_bank_transfer = async req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/initiat`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/initiat`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _verify_user_bank_account = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/banks_account/verification`, e, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/banks_account/verification`, e, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _fund_user_wallet = req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transactions/found_wallet`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transactions/found_wallet`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}
// export const _fetch_user_dashboard = async e => {
//     /api/v1/user-dashboard/account_details
//     const jwt_token = localStorage.konnect_token;
//     if (jwt_token) {
//         const token = Jwt_decrypt({ token: jwt_token })
//         return axiosCall(`/user-dashboard/account_details`, { headers: { "Authorization": "Bearer " + token } })
//     } else {
//         return axiosCall(`/user-dashboard/account_details`, { headers: { "Authorization": "Bearer " + "" } })
//     }
// }

const _get_invoice = async ({ trxt_ref }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/invoice/transaction_ref/${trxt_ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/invoice/transaction_ref/${trxt_ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_dates = async ({ type }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // /delivery-date/type/${type}
        return axiosCall(`/delivery-date/available_dates`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date/available_dates`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_transactions = async ({ status, tx_ref, transaction_id }) => {
    return axiosCall(`/transactions/verify_payment_callback?status=${status}&tx_ref=${tx_ref}&transaction_id=${transaction_id}`)
}

const _get_all_wise9ja = async () => {
    return axiosCall(`/wisenija_plan/user?page=1&per_page=20`)
}

const _get_bills = async ({ searchParams }) => {
    return axiosCall(`/bills/categories/search?search=${searchParams}`)
}

export const verify_user_email = async token => {
    return axiosCall(`/auth/${token}/confirm-email`)
}

export const _user_dashboard_referrals = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/referal`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/referal`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _user_dashboard_shop = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/shop`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/shop`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _user_dashboard_wise9ja = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export {
    _add_delivery_address, _bank_verification, _calc_commission, _change_password, _delivery_info, _generate_otp, _get_all_banks, _get_all_cities, _get_all_dates, _get_all_invoice, _get_all_pickup_locations, _get_all_referrals, _get_all_state, _get_all_wise9ja, _get_bills, _get_invoice, _get_user_wallet_history, _get_profile,
    _get_saved_address, _get_search_product, _get_selected_delivery_location, _get_selected_pickup_location, _payout,
    _rate_setting_by_id, _update_2fa_verification, _update_profile, _update_trans_pin, _verify_2fa, _verify_otp,
    _verify_transactions, _wallet_verification, _wise9ja_topup, pay_checkout, signIn, _fund_user_wallet,
    _confirm_user_transfer_pin
};

